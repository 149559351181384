<template>
    <StepContainer>
        <StepTitle :titles="[{ title: store.campaign!.description || '' }]" />
        <GeolocationSelection />
    </StepContainer>
</template>
<script setup lang="ts">
import { onBeforeMount, onUnmounted, ref, watch } from 'vue'
import { useRecipientSearchStore, useSendCampaignStore } from '../../stores'
import StepContainer from '../StepContainer.vue'
import StepTitle from '../StepTitle.vue'
import GeolocationSelection from './GeolocationSelection.vue'

const recipientSearchStore = useRecipientSearchStore()
const store = useSendCampaignStore()

const address = ref<string | null>(null)
const isRequestDone = ref(false)

watch(address, () => {
    if (isRequestDone.value) {
        isRequestDone.value = false
        recipientSearchStore.reset()
    }
})

onBeforeMount(() => {
    if (!store.campaign) {
        throw new Error('Store should have a campaign set')
    }
})

onUnmounted(() => {
    recipientSearchStore.reset()
})
</script>
