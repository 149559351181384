<template>
    <form @submit.prevent="onSubmit">
        <ContainerWhite>
            <div class="w-full md:w-8/12">
                <AVInput
                    class="mb-6"
                    :label="t('backoffice.campaigns.campaignNameLabel')"
                    :placeholder="
                        t('backoffice.campaigns.campaignNamePlaceholder')
                    "
                    v-bind="nameField.props.value"
                    v-on="nameField.listeners.value"
                />
                <div class="flex flex-col md:flex-row items-center">
                    <AVInput
                        class="mb-6"
                        :label="t('backoffice.campaigns.slugLabel')"
                        :placeholder="t('backoffice.campaigns.slugPlaceholder')"
                        v-bind="slugField.props.value"
                        v-on="slugField.listeners.value"
                    />
                    <AVButton
                        @click="updateSlug"
                        button-type="accent"
                        type="button"
                        form="custom-questions-form"
                        class="px-2 py-1 h-12 ml-0 md:ml-3 min-w-48"
                        ><i
                            class="fi fi-rs-refresh mr-2 translate-y-0.5 inline-block text-sm"
                        ></i
                        >{{ t('backoffice.campaigns.generateSlug') }}</AVButton
                    >
                </div>

                <AVTextarea
                    class="mb-6"
                    placeholder="backoffice.campaigns.descriptionPlaceholder"
                    :label="t('backoffice.campaigns.descriptionLabel')"
                    v-bind="descriptionField.props.value"
                    v-on="descriptionField.listeners.value"
                />
                <AVFileDropZone
                    name="image_social_networks"
                    :label="
                        t(
                            'backoffice.campaigns.createUpdateCampaign.imageSocialMedia'
                        )
                    "
                    :fileTypes="['svg', 'png', 'jpg', 'gif']"
                    :currentFileUrl="
                        crudStore.itemData?.social_networks_image || undefined
                    "
                    v-model="newCampaignImage"
                    :isImageFile="true"
                    @clear="clearImage"
                />
                <AVSelect
                    class="mb-6"
                    :label="t('backoffice.campaigns.languageLabel')"
                    :placeholder="t('backoffice.campaigns.languagePlaceholder')"
                    :options="applyI18nToSelectOptions(LOCALE_OPTIONS)"
                    v-bind="languageField.props.value"
                    v-on="languageField.listeners.value"
                />
                <AVSelectButtons
                    :label="t('backoffice.campaigns.interpellationModesLabel')"
                    :options="interpellationModeOptions"
                    :multiple="true"
                    v-bind="interpellationModesField.props.value"
                    v-on="interpellationModesField.listeners.value"
                />
                <AVSelect
                    class="mb-6"
                    :label="t('backoffice.campaigns.isGeoLocated')"
                    :options="[
                        { value: true, label: t('backoffice.yes') },
                        { value: false, label: t('backoffice.no') },
                    ]"
                    v-bind="isGeoLocatedField.props.value"
                    v-on="isGeoLocatedField.listeners.value"
                />
            </div>
            <div
                v-if="nonFieldErrors"
                class="w-full text-center text-error mb-3"
            >
                {{ nonFieldErrors }}
            </div>
        </ContainerWhite>
        <SaveButtons @save="onSave" :hasNext="true" />
    </form>
</template>

<script setup lang="ts">
import { RequestStatus } from '@/types'
import { computed, watch, ref } from 'vue'
import { LOCALE_OPTIONS } from '@/i18n'
import ContainerWhite from '@/AppBackoffice/components/ContainerWhite.vue'
import AVInput from '@/components/forms/AVInput.vue'
import AVTextarea from '@/components/forms/AVTextarea.vue'
import AVSelect from '@/components/forms/AVSelect.vue'
import SaveButtons from '../components/SaveButtons.vue'
import AVSelectButtons from '@/components/forms/AVSelectButtons.vue'
import AVFileDropZone from '@/components/forms/AVFileDropZone.vue'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { CampaignWrite, INTERPELLATION_MODE_OPTIONS } from '@/models/campaigns'
import {
    useCampaignBackofficeCrudStore,
    useCampaignCreateUpdateWizardStore,
    useCampaignExtraStore,
} from '@/AppBackoffice/campaigns/stores'
import { makeUseField, applyI18nToSelectOptions } from '@/utils/forms'
import { campaignStepBasicInfoValidator } from './validation'
import { usePredefinedToasts } from '../utils'
import { useI18n } from 'vue-i18n'
import AVButton from '@/components/forms/AVButton.vue'
import { slugify } from '@/utils/slug'

interface Props {
    campaignId?: string
}

const props = defineProps<Props>()

const { t } = useI18n()
const crudStore = useCampaignBackofficeCrudStore()
const wizardStore = useCampaignCreateUpdateWizardStore()
const extraStore = useCampaignExtraStore()
const predefinedToasts = usePredefinedToasts()

const nonFieldErrors = ref<string | null>(null)

const {
    validate,
    setErrors,
    meta,
    values: campaign,
} = useForm({
    // No idea why `toTypedSchema` seems to be needed here.
    // Without it, running validation always returns empty data.
    validationSchema: toTypedSchema(campaignStepBasicInfoValidator),
    initialValues: crudStore.itemData,
})
const useFieldCampaign = makeUseField<CampaignWrite>()
const nameField = useFieldCampaign('name')
const descriptionField = useFieldCampaign('description')
const languageField = useFieldCampaign('language')
const interpellationModesField = useFieldCampaign('interpellation_modes')
const isGeoLocatedField = useFieldCampaign('is_geo_located')
const slugField = useFieldCampaign('slug')

const interpellationModeOptions = computed(() =>
    INTERPELLATION_MODE_OPTIONS.map(({ value, label }) => ({
        value,
        label: t(label),
    }))
)

const newCampaignImage = ref<File | null>(null)
const clearImage = () => {
    newCampaignImage.value = new File([], '')
}

const updateSlug = () => {
    slugField.value.value = nameField.value.value
        ? slugify(nameField.value.value)
        : ''
}

const onSave = async () => {
    const { valid: isValid, values } = await validate()
    if (!isValid) {
        return false
    }

    let requestStatus: RequestStatus
    if (props.campaignId) {
        const interpellations = campaign.interpellations || []
        const campaignWrite: CampaignWrite = {
            ...campaign,
            ...values!,
            interpellations: interpellations,
        }
        requestStatus = await crudStore.runUpdate(
            props.campaignId,
            campaignWrite
        )
    } else {
        requestStatus = await crudStore.runCreate(values!)
    }

    if (requestStatus === RequestStatus.SUCCESS) {
        predefinedToasts.success()
        if (newCampaignImage.value instanceof File) {
            extraStore.updateCampaignImage(
                crudStore.itemData!.id,
                newCampaignImage.value
            )
        }

        wizardStore.setDirtyState(false)
    }

    return requestStatus
}

const onSubmit = async () => {
    if ((await onSave()) === RequestStatus.SUCCESS) {
        wizardStore.goToNextStep()
    }
}

watch(
    () => crudStore.itemValidationErrors,
    (backendValidationErrors) => {
        if (backendValidationErrors) {
            const { non_field_errors, ...fieldErrors } = backendValidationErrors
            setErrors(fieldErrors)
            if (non_field_errors) {
                nonFieldErrors.value = non_field_errors.join(', ')
            }
        }
    }
)
watch(() => meta.value.dirty, wizardStore.setDirtyState)
</script>
