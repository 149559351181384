import axios, { HttpStatusCode, InternalAxiosRequestConfig } from 'axios'
import { useAuthStore } from '@/AppBackoffice/user/stores'
import { useToastsStore } from '@/stores/toasts'
import { API_ROOT_URL, PUBLIC_API_ROOT_URL } from '@/constants'
import { Router } from 'vue-router'

export const setupAxiosDefaults = () => {
    // Never throw an error when receiving unexpected HTTP status code
    // We handle all status ourselves in the code.
    axios.defaults.validateStatus = () => true
}

/**
 * These interceptors are used for the app only, not for the embed.
 */
export const setupRequestInterceptorsForApp = (router: Router) => {
    axios.interceptors.request.use(makeInterceptAppRequest(router))
    axios.interceptors.response.use(interceptAnyResponse)
}

const makeInterceptAppRequest =
    (router: Router) => async (config: InternalAxiosRequestConfig) => {
        const authStore = useAuthStore()
        // const toastsStore = useToastsStore()
        config.headers = config.headers || {}
        if (false) {
            // TODO: check sessions
            router.push('/login')
        }

        if (authStore.isLoggedIn && authStore._auth) {
            // Check that we're contacting our private API before sending the token.
            // This is to avoid sending the token to third-party APIs.
            if (
                config.url?.startsWith(API_ROOT_URL) &&
                !config.url?.startsWith(PUBLIC_API_ROOT_URL)
            ) {
                // config.headers['Authorization'] =
                //     `Bearer ${authStore.accessToken}`
                config.headers['X-Session-Token'] = authStore._auth.session_id
            }
        }

        return config
    }

/**
 * Intercept response for app as well as embed.
 */
export const interceptAnyResponse = async <
    ResponseType extends { status: HttpStatusCode },
>(
    response: ResponseType
) => {
    const toastsStore = useToastsStore()
    if (response.status === HttpStatusCode.TooManyRequests) {
        toastsStore.addMessageTKey('shared.errors.throttled', 'danger')
        return response
    } else {
        return response
    }
}
